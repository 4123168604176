import { clearSession } from "@az/auth-ping";
import { LAST_LOGOUT, USER_DATA } from "../constants";
import { UserData } from "../types";

const getUID = (): string | null => {
    const userData = sessionStorage.getItem(USER_DATA);
    if (userData) {
      const parsedUserData: UserData = JSON.parse(userData);
      const { sub } = parsedUserData;
      return sub || null;
    }
    return null;
  };

export default function (){
    const uid = getUID();
    if (uid){
        const now = new Date();
        const end = `${now.toDateString()} ${now.toTimeString()} ms:${now.getTime()}`;
        localStorage.setItem(`${LAST_LOGOUT}_${uid}`, String(end));
        clearSession();
    }
};